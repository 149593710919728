section#FilterComponent {
  background-color: #fff;
  display: flex;
  padding: 1rem;
  gap: 1.5rem;
  justify-content: space-between;
  border-radius: 0.5rem;
  box-shadow: 6px 6px 5px #ebebeb, -6px -6px 7px #ffffff;
}
section#FilterComponent div.containers:first-child {
  justify-content: flex-start;
}
section#FilterComponent div.containers,
section#FilterComponent div.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 0.5rem;
  align-items: center;
}
@media (max-width: 700px) {
  section#FilterComponent div.containers,
  section#FilterComponent div.buttons {
    flex-wrap: wrap;
  }
}
section#FilterComponent div.containers div.viewTipe,
section#FilterComponent div.buttons div.viewTipe {
  border: 1px solid #dadada;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}
section#FilterComponent div.containers div.viewTipe div,
section#FilterComponent div.buttons div.viewTipe div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
}
section#FilterComponent div.containers div.viewTipe div.selected,
section#FilterComponent div.buttons div.viewTipe div.selected {
  background-color: #dadada;
}
section#FilterComponent div.containers div.viewTipe div img,
section#FilterComponent div.buttons div.viewTipe div img {
  height: 1.5rem;
  width: 2rem;
}
section#FilterComponent div.containers div.viewTipe div#list img,
section#FilterComponent div.buttons div.viewTipe div#list img {
  width: 2.5rem;
}
section#FilterComponent div.containers img,
section#FilterComponent div.buttons img {
  width: 17px;
  height: 17px;
}
section#FilterComponent div.containers div.day-by-day-filter,
section#FilterComponent div.buttons div.day-by-day-filter {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
}
section#FilterComponent div.containers div.day-by-day-filter input,
section#FilterComponent div.buttons div.day-by-day-filter input {
  width: 130px;
}
