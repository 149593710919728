main#TrainingPage section#training-videos {
  padding-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
}
@media (max-width: 1000px) {
  main#TrainingPage section#training-videos {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 850px) {
  main#TrainingPage section#training-videos {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 700px) {
  main#TrainingPage section#training-videos {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
main#TrainingPage section#training-videos div.training-video {
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
}
main#TrainingPage section#training-videos div.training-video section.video-header {
  display: flex;
  justify-content: center;
}
main#TrainingPage section#training-videos div.training-video section.video-header img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  aspect-ratio: 16/10;
  border-radius: 0.3rem;
  max-height: 100%;
}
main#TrainingPage section#training-videos div.training-video section.video-info {
  padding: 0.5rem 0;
}
main#TrainingPage section#training-videos section#video-player {
  padding: 1rem;
  padding-top: 2rem;
}
main#TrainingPage section#training-videos section#video-player iframe {
  border: none;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
main#TrainingPage section#training-videos section#video-player video {
  max-width: 90vw;
  height: 75vh;
  max-height: 70%;
}
main#TrainingPage section#upload-video {
  margin: 1rem;
}
main#TrainingPage section#upload-video div.buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}
main#TrainingPage section#progressBar {
  border: 1px solid #8f8f8f;
  height: 2rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
main#TrainingPage section#progressBar div#bar {
  background-color: rgb(98, 98, 12);
  min-width: 1rem;
  content: "";
}
main#TrainingPage section#progressBar span#progress {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  min-width: 10%;
  color: #fff;
  text-align: center;
  background-color: #504be9;
}
main#TrainingPage section#createVideo form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
main#TrainingPage section#createVideo form .videoPreview {
  display: flex;
  align-items: center;
  padding: 1rem;
}
main#TrainingPage section#createVideo form .videoPreview .invalidKey {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
main#TrainingPage section#createVideo form .videoPreview iframe {
  margin: auto 0;
  width: 100%;
  border: none;
  aspect-ratio: 16/9;
}
main#TrainingPage section#createVideo form div.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 800px) {
  main#TrainingPage section#createVideo form {
    grid-template-columns: 1fr;
  }
}
main#TrainingPage footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  gap: 0.5rem;
}
