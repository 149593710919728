main#SalesPage {
  display: grid;
  grid-template-rows: auto auto 4fr 0.1fr;
  gap: 10px;
}
main#SalesPage section {
  padding: 10px;
}
main#SalesPage section#products {
  background-color: #fff;
  border-radius: 0.5rem;
  min-height: 62vh;
}
main#SalesPage section#products #header {
  display: grid;
  grid-template-columns: 0.2fr 0.7fr 2fr 1fr 1fr 0.9fr 1fr 1fr minmax(110px, 1.5fr);
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  gap: 3px;
}
main#SalesPage section#products #header div {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 0.8rem;
}
main#SalesPage section#products #header div#product, main#SalesPage section#products #header div:first-child {
  justify-content: start;
}
main#SalesPage section#products #Container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
main#SalesPage section#products #Container .sale {
  border-bottom: 1px solid #ebebeb;
  display: grid;
  grid-template-columns: 0.2fr 0.7fr 2fr 1fr 1fr 0.9fr 1fr 1fr 1.5fr;
  padding: 5px;
  gap: 3px;
  border-radius: 5px;
}
main#SalesPage section#products #Container .sale div {
  display: flex;
  align-items: center;
  justify-content: center;
}
main#SalesPage section#products #Container .sale div.select.factory input {
  background-color: red;
}
main#SalesPage section#products #Container .sale div.product-name {
  justify-content: start;
  text-align: center;
}
main#SalesPage section#products #Container .sale div.store-name {
  text-align: center;
}
@media (max-width: 700px) {
  main#SalesPage section#products #Container .sale div.img {
    display: none;
  }
}
main#SalesPage section#products #Container .sale div.img img {
  max-width: 50px;
  max-height: 50px;
}
@media (max-width: 700px) {
  main#SalesPage section#products #Container .sale div > input {
    display: none;
  }
}
main#SalesPage section#products #Container .sale div.invoicing.value-negative {
  color: #d12953;
}
main#SalesPage section#products #Container .sale div.invoicing-mobile {
  display: none;
}
main#SalesPage section#products #Container .sale div.invoicing-mobile.value-negative {
  color: #d12953;
}
main#SalesPage section#products #Container .sale div .status-select {
  width: 110px;
  text-align: center;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem top 50%;
  background-size: 0.6rem auto;
}
main#SalesPage section#products #Container .sale div .status-select:disabled {
  background: none;
  opacity: 1;
  text-align: center;
}
main#SalesPage section#products #Container .sale div .status-p {
  width: 100px;
  text-align: center;
  padding: 3px 0;
  border-radius: 0.5rem;
}
main#SalesPage section#products #Container .sale div .status-select.pending {
  background-color: rgba(75, 44, 163, 0.1607843137);
  color: #391f84;
  text-align: left;
}
main#SalesPage section#products #Container .sale div .status-select.pending:disabled {
  text-align: center;
}
main#SalesPage section#products #Container .sale div .status-p.pending {
  background-color: rgba(75, 44, 163, 0.1607843137);
  color: #391f84;
}
main#SalesPage section#products #Container .sale div .status-select.paid,
main#SalesPage section#products #Container .sale div .status-p.paid {
  background-color: rgba(36, 255, 0, 0.1803921569);
  color: #14804a;
}
main#SalesPage section#products #Container .sale div .status-select.returned,
main#SalesPage section#products #Container .sale div .status-p.returned {
  background-color: rgba(221, 212, 0, 0.2);
  color: #ef9e00;
}
main#SalesPage section#products #Container .sale div .status-select.defect,
main#SalesPage section#products #Container .sale div .status-p.defect {
  background-color: rgba(255, 39, 39, 0.1803921569);
  color: #d12953;
}

#CreateSale {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 15px;
  padding: 10px;
}
#CreateSale header {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#CreateSale header h1 {
  font-size: 1.3rem;
}
#CreateSale header h3 {
  font-size: 1rem;
}
#CreateSale form {
  display: grid;
  grid-template-areas: "store store" "model data" "manufacturing quantity" "acquisition lucrofabrica" "price lucroloja" "buttons buttons";
  gap: 10px;
}
#CreateSale form div#manufacturing {
  grid-area: manufacturing;
  display: grid;
  grid-template-columns: 1fr;
}
#CreateSale form div#manufacturing.comission {
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 700px) {
  #CreateSale form div#manufacturing.comission {
    grid-template-columns: 1fr;
  }
}
#CreateSale form div#manufacturing div {
  display: flex;
  flex-direction: column;
}
@media (max-width: 700px) {
  #CreateSale form div#manufacturing {
    grid-template-columns: 1fr;
  }
}
#CreateSale form div#store {
  grid-area: store;
}
#CreateSale form div#store select {
  width: 100%;
}
#CreateSale form div#isshopee {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  width: 10rem;
}
#CreateSale form div#lucroloja {
  grid-area: lucroloja;
}
#CreateSale form div#lucroloja input {
  border-color: #058900;
}
#CreateSale form div#lucroloja.prejudice input {
  border-color: #d12929;
}
#CreateSale form div#lucrofabrica {
  grid-area: lucrofabrica;
}
#CreateSale form div#lucrofabrica.prejudice input {
  border-color: #d12929;
}
#CreateSale form div#quantity {
  grid-area: quantity;
}
#CreateSale form div#model {
  grid-area: model;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
#CreateSale form div#acquisition {
  grid-area: acquisition;
  display: grid;
  grid-template-columns: 1fr;
}
#CreateSale form div#acquisition.comission {
  grid-template-columns: 1fr 1fr;
}
#CreateSale form div#price {
  grid-area: price;
  display: flex;
}
@media (max-width: 700px) {
  #CreateSale form div#price {
    display: grid;
  }
}
#CreateSale form #data {
  grid-area: data;
}
#CreateSale form #buttons {
  grid-area: buttons;
  padding: 5px;
  display: flex;
  justify-content: end;
  gap: 10px;
}

#DeleteSales {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
}
#DeleteSales header {
  padding: 10px 0;
}
#DeleteSales header h1 {
  font-size: 1.3rem;
}
#DeleteSales .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
#DeleteSales .content p {
  text-align: left;
  width: 70%;
}
#DeleteSales .content form #buttons {
  padding: 5px;
  display: flex;
  justify-content: end;
  gap: 10px;
}
#DeleteSales .content form #buttons :disabled {
  cursor: default;
  opacity: 0.85;
}

@media (max-width: 700px) {
  #CreateSale form {
    grid-template-areas: "store" "model" "quantity" "manufacturing" "acquisition" "lucrofabrica" " price" "isshopee" " lucroloja" " data" " buttons";
  }
  main#SalesPage section#products #header {
    grid-template-columns: 1.5fr 1fr 0.8fr 1fr minmax(90px, 1fr);
    gap: 1px;
  }
  main#SalesPage section#products #header div.price,
  main#SalesPage section#products #header div.date,
  main#SalesPage section#products #header div.select,
  main#SalesPage section#products #header div.img {
    display: none;
  }
  main#SalesPage section#products #header div.store-name {
    justify-content: start;
  }
  main#SalesPage section#products #Container .sale {
    grid-template-columns: 1.5fr 1fr 0.8fr 1fr minmax(90px, 1fr);
    gap: 1px;
  }
  main#SalesPage section#products #Container .sale div.store-name {
    justify-content: start;
  }
  main#SalesPage section#products #Container .sale div.invoicing-mobile {
    display: inherit;
  }
  main#SalesPage section#products #Container .sale div.invoicing {
    display: none;
  }
  main#SalesPage section#products #Container .sale select {
    scale: 0.8;
  }
  main#SalesPage section#products #Container .sale div.select,
  main#SalesPage section#products #Container .sale div.price,
  main#SalesPage section#products #Container .sale div.date {
    display: none;
  }
}
