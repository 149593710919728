*,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inter;
  font-size: 15px;
  transition: 500ms linear;
}

body,
#root {
  background-color: #f9f9f9;
  min-height: 100vh;
}

input,
select {
  background-color: #ededef;
  border-radius: 5px;
  padding: 10px 7px;
  border: none;
}

input[type=checkbox] {
  padding: 0.3rem;
  accent-color: #504be9;
}

p {
  font-size: 110%;
}

h1 {
  font-size: 1.6rem;
  font-family: "Inter-bold";
}

h3 {
  color: #8f8f8f;
  font-size: 1.2rem;
}

h2.error {
  color: red;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 7px;
  border-radius: 6px;
  background: #504be9;
  border: none;
  cursor: pointer;
  color: #fff;
}
button:disabled {
  background-color: #8f8f8f;
  color: #000;
}
button.second {
  background-color: #fff;
  color: #000;
}
button.third {
  background-color: #f8f8f8;
  color: #000;
}
button.delete {
  background-color: #e94b4b;
}

@font-face {
  font-family: "inter";
  src: url(/public/fonts/inter/Inter-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Inter-Bold";
  src: url(/public/fonts/inter/Inter-Bold.ttf);
  font-weight: normal;
  font-style: normal;
}
