main#MainPage {
  display: grid;
  grid-template-columns: auto 5fr;
  background-color: #f5f5f5;
  gap: 5px;
  min-height: 100vh;
  padding-right: 0.5rem;
}

@media (max-width: 700px) {
  main#MainPage {
    display: block;
    padding: 5px;
  }
  main#MainPage section#SideBar {
    z-index: 100;
    transition: none;
    position: fixed;
    width: 1px;
  }
  main#MainPage section#SideBar.active {
    min-width: 300px;
    max-width: 80vw;
    display: grid;
  }
  main#MainPage section#SideBar.active span#selector {
    display: flex;
    transform: translateX(40%) rotate(0deg);
  }
  main#MainPage section#SideBar.active span {
    display: flex;
  }
  main#MainPage section#SideBar.active section {
    display: flex;
  }
  main#MainPage section#SideBar span#selector {
    display: flex;
    transform: translateX(90%) rotate(180deg);
  }
  main#MainPage section#SideBar span,
  main#MainPage section#SideBar section {
    display: none;
  }
}
