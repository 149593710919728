main#GroupPage section.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
  margin-top: 5px;
}
main#GroupPage section.container a {
  text-decoration: none;
}
main#GroupPage section.container div.group {
  height: 100%;
  cursor: pointer;
  padding: 1rem;
  background-color: #504be9;
  color: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  text-align: center;
  align-items: center;
  box-shadow: 6px 6px 7px #ebebeb, -6px -6px 7px #ffffff;
}
@media (max-width: 700px) {
  main#GroupPage section.container {
    grid-template-columns: repeat(3, 1fr);
  }
}

#deleteProduct footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
#deleteProduct footer button {
  cursor: pointer;
}

#editGroup #hasCommission {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
#editGroup #inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 10px;
}
#editGroup div#buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
}

#groupdetails #clean {
  width: 100%;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
}
#groupdetails .groupMembers .container {
  padding: 0.5rem 0;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(6, 1fr);
}
#groupdetails .groupMembers .container div.member {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 0.3rem;
  color: #ffffff;
  padding: 0.5rem;
  background-color: #504be9;
}
@media (max-width: 700px) {
  #groupdetails .groupMembers .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

#createGroup .form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
@media (max-width: 450px) {
  #createGroup .form {
    grid-template-columns: 1fr;
  }
}
#createGroup #hasCommission {
  display: flex;
  gap: 1rem;
  align-items: center;
}
#createGroup section.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
}

.groupPermissions {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.groupPermissions .permissionLine {
  max-width: 500px;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 0.5rem;
}
.groupPermissions .permissionLine div.buttons {
  display: flex;
  justify-content: space-evenly;
}
.groupPermissions .permissionLine div.buttons button.second {
  border: #504be9 1px solid;
}

#groupCatalog section div.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  padding: 0.5rem;
}
@media (max-width: 900px) {
  #groupCatalog section div.container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 700px) {
  #groupCatalog section div.container {
    grid-template-columns: repeat(2, 1fr);
  }
}
#groupCatalog section div.container div.product {
  padding: 0.2rem 0.3rem;
  box-shadow: 6px 6px 5px #ebebeb, -6px -6px 7px #ffffff;
  display: flex;
  grid-template-columns: 0.2fr 0.2fr;
  gap: 0.3rem;
  border-radius: 0.5rem;
}
#groupCatalog section div.container div.product .product-img {
  display: flex;
  align-items: center;
}
#groupCatalog section div.container div.product .product-img img {
  width: 100%;
  max-width: 5rem;
}
#groupCatalog section div.container div.product .product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#groupCatalog section div.clear {
  min-height: 5rem;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#addProduct div.clear {
  min-height: 5rem;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#addProduct footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
#addProduct section div.value {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 700px) {
  #addProduct section div.value {
    grid-template-columns: 1fr;
  }
}
#addProduct section.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  padding: 0.5rem;
}
@media (max-width: 900px) {
  #addProduct section.products {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 700px) {
  #addProduct section.products {
    grid-template-columns: repeat(2, 1fr);
  }
}
#addProduct section.products div.product {
  display: flex;
  padding: 0.2rem 0.3rem;
  box-shadow: 6px 6px 5px #ebebeb, -6px -6px 7px #ffffff;
  grid-template-columns: 0.2fr 0.2fr;
  gap: 0.3rem;
  align-items: center;
  border-radius: 0.5rem;
  height: 100%;
}
#addProduct section.products div.product .product-img {
  display: flex;
  align-items: center;
}
#addProduct section.products div.product .product-img img {
  width: 100%;
  max-width: 5rem;
}
#addProduct section.products div.product .product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#addProduct footer {
  padding: 0.5rem;
}

#updateGroupProduct section {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.5rem;
  gap: 1rem;
}
#updateGroupProduct section div#dropzone-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
#updateGroupProduct section div#dropzone-container div#deleteProduct {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  align-items: end;
}
#updateGroupProduct section div#dropzone-container div#deleteProduct button {
  margin: 0.5rem;
}
#updateGroupProduct section div#dropzone-container div#deleteProduct div {
  align-items: flex-start;
}
#updateGroupProduct section div.manufacturing {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#updateGroupProduct section div.values {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
#updateGroupProduct section div.values#shoope {
  grid-template-columns: 1fr 0.5fr 1fr;
}
#updateGroupProduct section div.values#shoope div#isShoope {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#updateGroupProduct section div.values span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
#updateGroupProduct section div.values span p {
  text-align: center;
}
#updateGroupProduct footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}
#updateGroupProduct #deleteProduct {
  display: flex;
}
