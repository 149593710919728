div.separator {
  width: 99%;
  margin: 5px auto;
}
div.separator div.line {
  width: 100%;
  height: 1px;
  margin: 3px 0;
  border-radius: 1px;
  border: 1px solid #757575;
}
div.separator span {
  color: #757575;
  font-size: 0.8rem;
}
