main#DashBoardPage main#container,
main#DashBoardPage main#container.adm {
  display: grid;
  grid-template-areas: "invoicing products" "graphic graphic";
  gap: 10px;
}
main#DashBoardPage main#container.adm,
main#DashBoardPage main#container.adm.adm {
  grid-template-areas: "invoicing products stores" "graphic graphic graphic";
}
@media (max-width: 1100px) {
  main#DashBoardPage main#container.adm,
  main#DashBoardPage main#container.adm.adm {
    grid-template-areas: "invoicing products " "stores  graphic";
  }
}
@media (max-width: 800px) {
  main#DashBoardPage main#container.adm,
  main#DashBoardPage main#container.adm.adm {
    grid-template-areas: "invoicing" " products" "stores" " graphic";
  }
}
main#DashBoardPage main#container section,
main#DashBoardPage main#container.adm section {
  border-radius: 0.5rem;
  background-color: #fff;
  min-height: 15rem;
  padding: 0.5rem;
}
main#DashBoardPage main#container section#invoncing,
main#DashBoardPage main#container.adm section#invoncing {
  grid-area: invoicing;
  padding: 1rem;
  padding-bottom: 0;
  position: relative;
}
main#DashBoardPage main#container section#invoncing .invoicing-graph-container,
main#DashBoardPage main#container.adm section#invoncing .invoicing-graph-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 5px;
}
main#DashBoardPage main#container section#invoncing .invoicing-graph-container .invoicing-graph,
main#DashBoardPage main#container.adm section#invoncing .invoicing-graph-container .invoicing-graph {
  width: 30px;
  height: 100%;
  background: linear-gradient(180deg, rgba(80, 75, 233, 0.6) 21%, rgba(255, 255, 255, 0) 100%);
  border-radius: 6px 6px 0 0;
}
main#DashBoardPage main#container section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-1,
main#DashBoardPage main#container.adm section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-1 {
  margin-bottom: -150px;
}
main#DashBoardPage main#container section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-2,
main#DashBoardPage main#container.adm section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-2 {
  margin-bottom: -100px;
}
main#DashBoardPage main#container section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-3,
main#DashBoardPage main#container.adm section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-3 {
  margin-bottom: -50px;
}
main#DashBoardPage main#container section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-4,
main#DashBoardPage main#container.adm section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-4 {
  margin-bottom: 0px;
}
main#DashBoardPage main#container section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-5,
main#DashBoardPage main#container.adm section#invoncing .invoicing-graph-container .invoicing-graph.invoicing-graph-5 {
  margin-bottom: -60px;
}
@media (max-width: 700px) {
  main#DashBoardPage main#container section#invoncing,
  main#DashBoardPage main#container.adm section#invoncing {
    display: flex;
    justify-content: space-around;
  }
  main#DashBoardPage main#container section#invoncing .invoicing-graph-container,
  main#DashBoardPage main#container.adm section#invoncing .invoicing-graph-container {
    position: relative;
  }
}
main#DashBoardPage main#container section#products, main#DashBoardPage main#container section#stores,
main#DashBoardPage main#container.adm section#products,
main#DashBoardPage main#container.adm section#stores {
  grid-area: products;
  display: flex;
  flex-direction: column;
}
main#DashBoardPage main#container section#products section#container, main#DashBoardPage main#container section#stores section#container,
main#DashBoardPage main#container.adm section#products section#container,
main#DashBoardPage main#container.adm section#stores section#container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
main#DashBoardPage main#container section#products section#container div.product,
main#DashBoardPage main#container section#products section#container div.store, main#DashBoardPage main#container section#stores section#container div.product,
main#DashBoardPage main#container section#stores section#container div.store,
main#DashBoardPage main#container.adm section#products section#container div.product,
main#DashBoardPage main#container.adm section#products section#container div.store,
main#DashBoardPage main#container.adm section#stores section#container div.product,
main#DashBoardPage main#container.adm section#stores section#container div.store {
  display: grid;
  grid-template-columns: 1fr 2fr 0.7fr;
  align-items: center;
  font-size: 10px;
  justify-content: space-around;
}
main#DashBoardPage main#container section#products section#container div.product img,
main#DashBoardPage main#container section#products section#container div.store img, main#DashBoardPage main#container section#stores section#container div.product img,
main#DashBoardPage main#container section#stores section#container div.store img,
main#DashBoardPage main#container.adm section#products section#container div.product img,
main#DashBoardPage main#container.adm section#products section#container div.store img,
main#DashBoardPage main#container.adm section#stores section#container div.product img,
main#DashBoardPage main#container.adm section#stores section#container div.store img {
  max-width: 4rem;
  max-height: 4rem;
}
main#DashBoardPage main#container section#stores,
main#DashBoardPage main#container.adm section#stores {
  grid-area: stores;
}
main#DashBoardPage main#container section#graphic,
main#DashBoardPage main#container.adm section#graphic {
  grid-area: graphic;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}
main#DashBoardPage main#container section#graphic div#filters,
main#DashBoardPage main#container.adm section#graphic div#filters {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0;
}

@media (max-width: 700px) {
  main#DashBoardPage main#container {
    display: grid;
    grid-template-areas: "invoicing" " products" "stores" " graphic";
    gap: 10px;
  }
  main#DashBoardPage main#container section {
    border-radius: 0.5rem;
    background-color: #fff;
  }
  main#DashBoardPage main#container section#invoncing {
    min-height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  main#DashBoardPage main#container section#invoncing .invoicing-info div h1 {
    text-align: center;
  }
  main#DashBoardPage main#container section#invoncing .invoicing-graph-container {
    display: none;
  }
}
