main#ProductsPage {
  margin-bottom: 1rem;
}
main#ProductsPage main#container {
  margin-top: 1rem;
}
main#ProductsPage main#container.view-grid {
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
  align-items: center;
}
main#ProductsPage main#container.view-grid div.product-progressbar-container,
main#ProductsPage main#container.view-grid .product-possition,
main#ProductsPage main#container.view-grid .product-cont {
  display: none;
}
@media (max-width: 1300px) {
  main#ProductsPage main#container.view-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 1000px) {
  main#ProductsPage main#container.view-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 700px) {
  main#ProductsPage main#container.view-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
main#ProductsPage main#container.view-grid div.product {
  background-color: #fff;
  min-height: 10rem;
  height: 100%;
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  box-shadow: 6px 6px 14px #ebebeb, -6px -6px 14px #ffffff;
}
main#ProductsPage main#container.view-grid div.product .product-img {
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 0.5rem;
}
main#ProductsPage main#container.view-grid div.product .product-img img {
  width: 100%;
  margin: auto 0;
}
main#ProductsPage main#container.view-grid div.product .product-info {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
}
main#ProductsPage main#container.view-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
main#ProductsPage main#container.view-list #header-products {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr 0.5fr 2fr;
  gap: 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
}
main#ProductsPage main#container.view-list #header-products div {
  display: flex;
  align-items: center;
  justify-content: center;
}
main#ProductsPage main#container.view-list .product {
  background-color: #fff;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr 0.5fr 2fr;
  gap: 0.5rem;
}
main#ProductsPage main#container.view-list .product div {
  display: flex;
  align-items: center;
  justify-content: center;
}
main#ProductsPage main#container.view-list .product div p {
  display: none;
}
main#ProductsPage main#container.view-list .product .product-img {
  display: flex;
  justify-content: center;
}
main#ProductsPage main#container.view-list .product .product-img img {
  max-width: 100%;
  max-height: 4rem;
  margin: 0 auto;
}
main#ProductsPage main#container.view-list .product .product-progressbar-container {
  display: flex;
  padding: 0 1rem;
}
main#ProductsPage main#container.view-list .product .product-progressbar-container .product-progressbar {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
main#ProductsPage main#container.view-list .product .product-progressbar-container .product-progressbar span {
  border-radius: 1rem;
  background-color: #504be9;
  height: 1rem;
}
main#ProductsPage footer {
  background-color: #fff;
  border-radius: 0.5rem;
}

main#createProduct section,
main#updateProduct section {
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  padding: 0.5rem;
  gap: 1rem;
}
@media (max-width: 800px) {
  main#createProduct section,
  main#updateProduct section {
    grid-template-columns: 1fr;
  }
}
main#createProduct section div#dropzone-container,
main#updateProduct section div#dropzone-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
main#createProduct section div#dropzone-container div#deleteProduct,
main#updateProduct section div#dropzone-container div#deleteProduct {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
  align-items: end;
}
main#createProduct section div#dropzone-container div#deleteProduct button,
main#updateProduct section div#dropzone-container div#deleteProduct button {
  margin: 0.5rem;
}
main#createProduct section div#dropzone-container div#deleteProduct div,
main#updateProduct section div#dropzone-container div#deleteProduct div {
  align-items: flex-start;
}
main#createProduct section div.manufacturing,
main#updateProduct section div.manufacturing {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
main#createProduct section div.values,
main#updateProduct section div.values {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
main#createProduct section div.values span,
main#updateProduct section div.values span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
main#createProduct section div.values span p,
main#updateProduct section div.values span p {
  text-align: center;
}
main#createProduct footer,
main#updateProduct footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}
#pagination {
  display: flex !important
}
