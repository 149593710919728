main#Classification {
  display: flex;
  flex-direction: column;
  grid-template-rows: 0.5fr 0.5fr 7fr 0.5fr;
  padding: 0 5px;
  gap: 5px;
  min-height: 99vh;
}
main#Classification main,
main#Classification footer,
main#Classification section#Filters {
  padding: 5px;
}
main#Classification section#Filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main#Classification section#Filters div {
  display: flex;
  gap: 5px;
  align-items: center;
}
main#Classification section#Filters div div#dateFilters {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 13.5rem;
}
main#Classification main {
  padding: 10px;
  background-color: #fff;
  border-radius: 0.5rem;
  min-height: 65vh;
  display: grid;
  grid-template-rows: auto 1fr;
}
main#Classification main section#StoresList div.stores-classification-header {
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
main#Classification main section#StoresList div.store-classification,
main#Classification main section#StoresList div.stores-classification-header {
  display: grid;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid #ccd5e1;
  grid-template-columns: 0.5fr 1fr 1fr 0.8fr;
}
main#Classification main section#StoresList div.store-classification div,
main#Classification main section#StoresList div.stores-classification-header div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
main#Classification main section#StoresList div.store-classification div.store-name,
main#Classification main section#StoresList div.stores-classification-header div.store-name {
  justify-content: start;
}
main#Classification main section#StoresList div.store-classification div.landing,
main#Classification main section#StoresList div.stores-classification-header div.landing {
  justify-content: flex-start;
}
main#Classification main section#StoresList div.store-classification img,
main#Classification main section#StoresList div.stores-classification-header img {
  max-width: 3rem;
}
@media (max-width: 700px) {
  main#Classification main section#StoresList div.store-classification img,
  main#Classification main section#StoresList div.stores-classification-header img {
    max-width: 2.2rem;
  }
}
main#Classification main section#StoresList div.store-classification {
  cursor: pointer;
}
main#Classification main section#StoresList div.store-classification:hover {
  background-color: #ffffff;
  filter: brightness(0.95);
}
main#Classification main section#StoresList div#container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
}
main#Classification main section#StoresList div#container.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
main#Classification footer {
  background-color: #fff;
  border-radius: 0.5rem;
}

section#CreateStore {
  display: grid;
  grid-template-rows: 1fr 4fr;
  gap: 10px;
  padding: 10px;
}
section#CreateStore main form {
  display: grid;
  grid-template-areas: "name groupId" "phone email" "storeName storeName" "buttons buttons";
  gap: 10px;
}
section#CreateStore main form div#groupId {
  grid-area: groupId;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
section#CreateStore main form div#name {
  grid-area: name;
}
section#CreateStore main form div#storeName {
  grid-area: storeName;
}
section#CreateStore main form div#buttons {
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

@media (max-width: 700px) {
  section#CreateStore {
    display: grid;
    grid-template-rows: 1fr 4fr;
    gap: 10px;
    padding: 10px;
  }
  section#CreateStore main form {
    display: grid;
    grid-template-areas: "name" "groupId" "phone" " email" "storeName" " buttons";
    gap: 10px;
  }
  section#CreateStore main form div#name {
    grid-area: name;
  }
  section#CreateStore main form div#buttons {
    grid-area: buttons;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
}
#detailsStore .container {
  padding: 0 1rem;
  margin-top: 1rem;
}
#detailsStore .container .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
}
#detailsStore .container .row .info .detailsStore-ranking {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 0.5rem;
}
#detailsStore .container .row .info .detailsStore-ranking p {
  margin: 0;
}
#detailsStore .container .row .info .detailsStore-ranking img {
  width: 30px;
}
#detailsStore .container .row .info .detailsStore-phone {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 0.5rem;
}
#detailsStore .container .row .info .detailsStore-phone p {
  margin: 0;
}
#detailsStore .container .row .info .detailsStore-phone img {
  width: 25px;
}
#detailsStore .container .row .info .error,
#detailsStore .container .row .info .success {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
#detailsStore .container .row .info .error {
  color: #c20c0c;
}
#detailsStore .container .row .info .success {
  color: #2ca706;
}
#detailsStore .container .row .info div {
  font-weight: bold;
}
#detailsStore .container .row .info p {
  color: #504be9;
  font-weight: bold;
  margin-top: 0.5rem;
}
#detailsStore .container .row .info button {
  padding: 0.5rem 3rem;
  border-radius: 100px;
  box-shadow: 1px 1px 1px black;
  margin-top: 1rem;
}
@media (max-width: 700px) {
  #detailsStore .container .row {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  #detailsStore .container .row .info button {
    margin: 0 auto;
  }
}

#editStore div#container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
#editStore div#container div.group {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}
#editStore .buttons {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  justify-content: flex-end;
}
#editStore .buttons .error {
  color: #c20c0c;
}
