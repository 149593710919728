section#calendarComponent {
  user-select: none;
  padding: 0.3rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  width: 20rem;
  perspective: 1;
}
section#calendarComponent section#header {
  border-bottom: 1px solid rgba(34, 51, 34, 0.2);
}
section#calendarComponent section#header header {
  display: flex;
  justify-content: space-between;
}
section#calendarComponent section#header header div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #95a3b7;
}
section#calendarComponent section#header header div.buttons {
  display: flex;
  gap: 0.3rem;
}
section#calendarComponent section#header header div.buttons div {
  color: #000;
  padding: 0.3rem;
}
section#calendarComponent section#header section#days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  color: #95a3b7;
}
section#calendarComponent section#header section#days div {
  display: flex;
  justify-content: center;
  padding: 0.3rem;
}
section#calendarComponent section#month {
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  gap: 0.2rem;
}
section#calendarComponent section#month section.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
section#calendarComponent section#month section.week div.day {
  margin: 0 auto;
  aspect-ratio: 1/1;
  height: 2rem;
  align-items: center;
  display: flex;
  padding: 0.1rem;
  justify-content: center;
  border-radius: 50%;
  color: #95a3b7;
  cursor: pointer;
  transition-duration: 200ms;
}
section#calendarComponent section#month section.week div.day.currentMonth {
  color: #000;
}
section#calendarComponent section#month section.week div.day.now {
  border: 1px solid #95a3b7;
}
section#calendarComponent section#month section.week div.day.selected {
  animation: none;
  color: #fff;
  background-color: #504be9;
}
