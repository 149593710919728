div#ModalBackground {
  z-index: 100;
  position: fixed;
  width: 100%;
  min-height: 100dvh;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5058823529);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
div#ModalBackground.clear {
  background-color: rgba(0, 0, 0, 0.1450980392);
}
div#ModalBackground section#ModalComponent {
  background-color: #fff;
  position: relative;
  min-width: 60vw;
  max-height: 95vh;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  padding: 10px;
  overflow: auto;
}
div#ModalBackground section#ModalComponent span#close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

@media (max-width: 700px) {
  div#ModalBackground section#ModalComponent {
    max-width: 100vw;
    min-width: 90vw;
  }
}
