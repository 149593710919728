main#TermsPage {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}
main#TermsPage section.container {
  background-color: #fff;
  padding: 1rem;
  margin: 1rem auto;
  max-width: 700px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
