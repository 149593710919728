#pagination {
  display: flex;
  justify-content: end;
  padding: 10px;
  gap: 5px;
}
#pagination div {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 3px;
}
#pagination div.active {
  background-color: #504be9;
}
#pagination div.reverse {
  transform: rotate(180deg);
}
