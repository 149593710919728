section#SideBar {
  display: grid;
  grid-template-rows: 9fr 1fr 0.5fr;
  height: 100dvh;
  width: 200px;
  z-index: 9;
  position: sticky;
  top: 0;
  gap: 5px;
  background-color: #fff;
}
section#SideBar section#options {
  position: sticky;
}
section#SideBar section.options {
  bottom: 0;
  position: absolute;
}
section#SideBar section#select-store {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem;
}
section#SideBar section#select-store div.img-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
section#SideBar section#select-store div.img-container img {
  max-height: 5rem;
  width: 100%;
  max-width: 6rem;
}
section#SideBar section#select-store div.select-store-options {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
}
@media (max-width: 500px) {
  section#SideBar section#select-store div.select-store-options {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
  }
}
section#SideBar section#select-store div.select-store-options div {
  justify-content: center;
  align-items: center;
  display: flex;
}
section#SideBar section#select-store div#select-store-container {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 5px;
  cursor: default;
}
section#SideBar section#select-store div#select-store-container.admin {
  background-color: #f5f5f5;
  cursor: pointer;
}
section#SideBar section#select-store div#select-store-container .titles h3 {
  font-size: 0.9rem;
}
section#SideBar section#select-store section.modal-select-store {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
section#SideBar section#select-store section.modal-select-store header {
  width: 100%;
}
section#SideBar section#select-store section.modal-select-store header h1 {
  font-size: 1.4rem;
}
section#SideBar section#select-store section.modal-select-store div.stores-container {
  width: 100%;
  margin-top: 1rem;
  display: grid;
  align-items: center;
  gap: 0.3rem;
  grid-template-columns: 1fr 1fr;
}
section#SideBar section#select-store section.modal-select-store div.stores-container div.store {
  background-color: #f6f6f6;
  padding: 0.8rem;
  border-radius: 0.3rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}
section#SideBar section#select-store section.modal-select-store div.stores-container div.store:hover {
  background-color: #dfdfdf;
}
section#SideBar section#select-store section.modal-select-store div.stores-container div.store div.store-rank img {
  max-width: 2.3rem;
  max-height: 2.3rem;
}
section#SideBar span#selector {
  background-color: #fff;
  display: none;
  position: absolute;
  right: 0px;
  top: 47px;
  transform: translateX(90%);
  border-radius: 5px;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  border: 1px #757575 solid;
}
section#SideBar span#selector img {
  transform: rotate(180deg);
  width: 1.7rem;
}
section#SideBar span {
  display: flex;
  flex-direction: column;
}
section#SideBar span nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}
section#SideBar span nav .NavButton {
  padding: 7px;
  border-radius: 5px;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 2px;
  transition: none;
  cursor: pointer;
}
section#SideBar span nav .NavButton:hover {
  background-color: #f5f5f5;
}
section#SideBar span nav .NavButton div {
  display: flex;
  align-items: center;
}
section#SideBar span nav .NavButton div.icon {
  justify-content: center;
}
