.meta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
}
.meta .meta-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
.meta .meta-info .meta-info-ranking {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.meta .meta-info .meta-info-ranking > img {
  padding-right: 3px;
  max-width: 2.3rem;
  max-height: 2.3rem;
}
.meta .meta-progressbar-container {
  padding-top: 10px;
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: center;
}
.meta .meta-progressbar-container .meta-progressbar {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  border-radius: 100px;
}
.meta .meta-progressbar-container .meta-progressbar > span {
  display: inline-block;
  background-color: #504be9;
  height: 100%;
  border-radius: 100px;
  width: 100%;
}
