main#LoginPage {
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  gap: 10px;
}
main#LoginPage section#Login {
  min-width: 350px;
  max-width: 600px;
  padding: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 10px;
}
main#LoginPage section#Login section,
main#LoginPage section#Login section form {
  width: 100%;
  padding: 0 10px 10px 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
main#LoginPage section#Login section#header,
main#LoginPage section#Login section form#header {
  align-items: start;
}
main#LoginPage section#Login section#header img,
main#LoginPage section#Login section form#header img {
  width: 100px;
  max-width: 80%;
}
main#LoginPage section#Login section#options,
main#LoginPage section#Login section form#options {
  align-items: center;
}
main#LoginPage #image {
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: #f9fbfc;
  overflow: hidden;
  padding-left: 20vw;
}
main#LoginPage #image img {
  box-shadow: -3px 7px 17px rgba(29, 29, 29, 0.8274509804);
  transform: translateX(calc(727px - 50vw));
  height: 80vh;
}

#resetPassword .container,
#ForgotPassword .container {
  padding: 1rem;
}
#resetPassword .container .buttons,
#ForgotPassword .container .buttons {
  padding: 0.5rem;
  display: flex;
  justify-content: end;
}

@media (max-width: 600px) {
  main#LoginPage #image img {
    transform: translateX(calc(727px - 45vw));
  }
}
@media (max-width: 700px) {
  main#LoginPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0 15px;
  }
  main#LoginPage section#Login {
    height: auto;
  }
  main#LoginPage #image {
    display: none;
  }
}
