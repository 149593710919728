header#HeaderComponent {
  display: flex;
  flex-direction: column;
  padding-top: 0.7rem;
}
header#HeaderComponent section#content {
  display: flex;
  padding: 0.7rem;
  align-items: center;
  justify-content: space-between;
}
header#HeaderComponent input {
  background-color: beige;
  border: 1px solid #d2d2d2;
}
header#HeaderComponent h3 {
  margin-left: 10px;
  margin-bottom: -5px;
}
