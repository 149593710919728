main#InvoicePage section#invoice-container {
  margin-top: 0.5rem;
  border-radius: 0.5rem;
}
main#InvoicePage section#invoice-container section#invoice-header div {
  display: flex;
  align-items: center;
  justify-content: center;
}
main#InvoicePage section#invoice-container section#invoice-header, main#InvoicePage section#invoice-container section#invoice-list section.invoice-item {
  border-radius: 0.5rem;
  background-color: #fff;
  display: grid;
  grid-template-columns: 0.2fr 0.3fr 1fr 0.7fr 1fr minmax(96px, 1fr);
}
@media (max-width: 750px) {
  main#InvoicePage section#invoice-container section#invoice-header, main#InvoicePage section#invoice-container section#invoice-list section.invoice-item {
    grid-template-columns: 1fr 0.6fr 0.6fr minmax(96px, 1fr);
    padding: 0.5rem;
  }
  main#InvoicePage section#invoice-container section#invoice-header .invoice-checkbox, main#InvoicePage section#invoice-container section#invoice-list section.invoice-item .invoice-checkbox {
    display: none;
  }
}
@media (max-width: 750px) and (max-width: 750px) {
  main#InvoicePage section#invoice-container section#invoice-header .invoice-img, main#InvoicePage section#invoice-container section#invoice-list section.invoice-item .invoice-img {
    display: none;
  }
}
main#InvoicePage section#invoice-container section#invoice-header {
  padding: 0.9rem 0.5rem;
}
main#InvoicePage section#invoice-container section#invoice-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0;
  min-height: 58vh;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item {
  padding: 0.5rem 0.5rem;
  cursor: pointer;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div {
  display: flex;
  align-items: center;
  justify-content: center;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div.invoice-amount-mobile {
  display: none;
}
@media (max-width: 750px) {
  main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div.invoice-status {
    scale: 0.8;
  }
  main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div.invoice-amount {
    display: none;
  }
  main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div.invoice-amount-mobile {
    display: flex;
  }
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div.invoice-title {
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  gap: 0.2rem;
  flex-direction: column;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-select {
  width: 110px;
  text-align: center;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem top 50%;
  background-size: 0.6rem auto;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-select:disabled {
  background: none;
  opacity: 1;
  text-align: center;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-p {
  width: 100px;
  text-align: center;
  padding: 3px 0;
  border-radius: 0.5rem;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-select.pending {
  background-color: rgba(75, 44, 163, 0.1607843137);
  color: #391f84;
  text-align: left;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-select.pending:disabled {
  text-align: center;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-p.pending {
  background-color: rgba(75, 44, 163, 0.1607843137);
  color: #391f84;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-select.paid,
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-p.paid {
  background-color: rgba(36, 255, 0, 0.1803921569);
  color: #14804a;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-select.returned,
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-p.returned {
  background-color: rgba(221, 212, 0, 0.2);
  color: #ef9e00;
}
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-select.defect,
main#InvoicePage section#invoice-container section#invoice-list section.invoice-item div .status-p.defect {
  background-color: rgba(255, 39, 39, 0.1803921569);
  color: #d12953;
}
main#InvoicePage main#InvoiceDetails section#container {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}
main#InvoicePage main#InvoiceDetails section#container #sales-list header,
main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item,
main#InvoicePage main#InvoiceDetails section#container footer {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr 1fr 1fr;
}
@media (max-width: 700px) {
  main#InvoicePage main#InvoiceDetails section#container #sales-list header,
  main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item,
  main#InvoicePage main#InvoiceDetails section#container footer {
    grid-template-columns: 1fr 0.7fr 0.5fr 1fr;
  }
  main#InvoicePage main#InvoiceDetails section#container #sales-list header .sale-img,
  main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item .sale-img,
  main#InvoicePage main#InvoiceDetails section#container footer .sale-img {
    display: none;
  }
}
main#InvoicePage main#InvoiceDetails section#container #sales-list header div,
main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item div,
main#InvoicePage main#InvoiceDetails section#container footer div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
main#InvoicePage main#InvoiceDetails section#container #sales-list header div.sale-name,
main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item div.sale-name,
main#InvoicePage main#InvoiceDetails section#container footer div.sale-name {
  justify-content: flex-start;
}
main#InvoicePage main#InvoiceDetails section#container #sales-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
main#InvoicePage main#InvoiceDetails section#container #sales-list header {
  padding: 0.5rem;
}
main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item {
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
}
main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item div.sale-name {
  justify-content: start;
}
main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item div.sale-img {
  justify-content: flex-start;
}
main#InvoicePage main#InvoiceDetails section#container #sales-list div.sale-item div.sale-img img {
  max-height: 3rem;
  max-width: 3rem;
}
main#InvoicePage main#InvoiceDetails section#payment {
  padding: 0.5rem;
  display: flex;
  gap: 1rem;
}
main#InvoicePage main#InvoiceDetails section#payment .qrcode svg {
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
main#InvoicePage main#InvoiceDetails section#payment #infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
main#InvoicePage main#InvoiceDetails footer {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

main#createInvoice main {
  display: flex;
  justify-content: space-between;
}
main#createInvoice section#buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.5rem;
}
main#createInvoice main#selectDate {
  display: flex;
}
@media (max-width: 700px) {
  main#createInvoice main#selectDate {
    flex-direction: column;
    align-items: center;
  }
}
main#createInvoice main#selectStores {
  display: flex;
  flex-direction: column;
}
main#createInvoice main#selectStores header {
  display: flex;
}
main#createInvoice main#selectStores header input {
  width: 100%;
}
main#createInvoice main#selectStores section#stores-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem 0;
  gap: 0.5rem;
}
main#createInvoice main#selectStores section#stores-container div.store {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: #000 1px solid;
}
main#createInvoice main#selectStores section#stores-container div.store.selected {
  transition: none;
  border-color: #504be9;
  background-color: #f9f9ff;
  color: #504be9;
}
