div#drop-zone {
  cursor: pointer;
  padding: 5px;
  display: flex;
  width: 100%;
  max-width: 20rem;
  text-align: center;
  height: 12rem;
  border: 3px dashed #ddd;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  gap: 0.5rem;
}
div#drop-zone h3 {
  font-size: 1.4rem;
}
div#drop-zone p {
  font-size: 0.9rem;
}
div#drop-zone img {
  max-width: 98%;
  max-height: 98%;
}
div#drop-zone:hover, div#drop-zone.active {
  background-color: #ddd;
  border-color: #aaa;
}
div#drop-zone input {
  display: none;
}
div#drop-zone.error {
  border-color: #e94b4b;
}
div#drop-zone.error h3 {
  color: #e94b4b;
}
