div.InputComponent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px;
  min-width: 90%;
}
div.InputComponent input.error {
  border: 1px solid #e94b4b;
  color: #f13838;
}
div.InputComponent span#error {
  margin-top: -5px;
  color: #f13838;
}
div.InputComponent #content {
  flex-direction: column;
  min-width: 90%;
  display: flex;
  position: relative;
}
div.InputComponent #content span {
  height: 100%;
  width: 25px;
  display: flex;
  margin: auto;
  position: absolute;
  right: 10px;
  align-items: center;
}
div.InputComponent #content span img {
  height: 50%;
}
